import type { Ref } from "vue";
import { computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

import type {
  ActiveConfigPanel,
  IActiveConfigPanelConditional,
  IAttribute,
  IDataService,
  IDataServices,
  IDataServiceService,
  IOrchestrationTemplate,
  IServiceAttribute,
  IStep
} from "@/models/orchestration";
import {
  ALL_OPTIONS_SSN_CONFIDENCE_LEVELS,
  COMMON_OPTIONS_GREATER_SSN_CONFIDENCE_LEVELS,
  COMMON_OPTIONS_LESSER_SSN_CONFIDENCE_LEVELS,
  DEFAULT_DO_TRIGGER,
  DO_SAVE_TEMPLATE_MODES,
  GREATER_TYPES_SSN_CONFIDENCE_LEVELS,
  LESSER_TYPES_SSN_CONFIDENCE_LEVELS,
  SERVICE_CUSTOM_ATTRIBUTES,
  SERVICE_TRIGGER
} from "@/helpers/constants";
import uniq from "lodash/uniq";

const getServiceAttribute = (
  serviceName: string,
  attribute: IAttribute
): IServiceAttribute => {
  return {
    key: attribute.id,
    name: attribute.name,
    meta: attribute.meta,
    service: serviceName,
    fetchesData: attribute.fetches_data
  };
};

export const useDataOrchestration = () => {
  const { getters, commit } = useStore();

  const activeTemplate = computed<IOrchestrationTemplate>(
    () => getters["orchestration/activeTemplate"]
  );

  const activeStep = computed<IStep | null>(
    () => getters["orchestration/activeStep"]
  );

  const serviceInfo = computed<IDataService | undefined>(() =>
    getters["orchestration/serviceInfoByTitle"](activeStep.value?.title)
  );

  const activeStepProvider = computed<IDataServiceService | undefined>(
    () =>
      serviceInfo.value?.services?.find(
        ({ id }) => id === (activeStep.value?.provider || null)
      )
  );

  const activeStepProviderDetails = computed<
    Record<string, IAttribute[]> | undefined
  >(() => {
    if (activeStepProvider.value) {
      return serviceInfo.value?.data_service_details?.[
        activeStepProvider.value.id
      ];
    }
    return undefined;
  });

  const activeConfigPanel = computed<ActiveConfigPanel | null>(
    () => getters["orchestration/activeConfigPanel"]
  );

  const activeTemplateSteps = computed<IStep[]>(
    () => getters["orchestration/activeTemplateSteps"]
  );

  const activeTemplateHasSteps = computed(
    () => !!activeTemplateSteps.value.length
  );

  const activeConfigPanelConditional =
    computed<IActiveConfigPanelConditional | null>(
      () => getters["orchestration/activeConfigPanelConditional"]
    );

  const deselectStep = () => {
    if (activeConfigPanel.value) {
      commit("orchestration/setActiveConfigPanel", "");
    }
    if (activeConfigPanelConditional.value) {
      commit("orchestration/setActiveConfigPanelConditional", null);
    }
    if (activeStep.value?.id) {
      commit("orchestration/setActiveStep", {});
    }
  };

  return {
    activeTemplate,
    deselectStep,
    activeStep,
    activeStepProvider,
    activeStepProviderDetails,
    activeTemplateSteps,
    activeTemplateHasSteps,
    serviceInfo
  };
};

export const useDoActiveTemplateStatus = () => {
  const { activeTemplate } = useDataOrchestration();

  const isActiveTemplatePublished = computed(
    () => activeTemplate.value.status === DO_SAVE_TEMPLATE_MODES.publish
  );

  return { isActiveTemplatePublished };
};

export const useDoActiveTemplateTrigger = () => {
  const { activeTemplate } = useDataOrchestration();

  const trigger = computed(
    () => activeTemplate.value.trigger || DEFAULT_DO_TRIGGER
  );

  return { trigger };
};

export const useSentilinkConfidenceLevels = (
  type: Ref<
    | (typeof GREATER_TYPES_SSN_CONFIDENCE_LEVELS)[number]
    | (typeof LESSER_TYPES_SSN_CONFIDENCE_LEVELS)[number]
    | string
  >
) => {
  const sentilinkConfidenceLevels = computed(() => {
    if (
      LESSER_TYPES_SSN_CONFIDENCE_LEVELS.includes(
        type.value as (typeof LESSER_TYPES_SSN_CONFIDENCE_LEVELS)[number]
      )
    ) {
      return COMMON_OPTIONS_LESSER_SSN_CONFIDENCE_LEVELS;
    } else if (
      GREATER_TYPES_SSN_CONFIDENCE_LEVELS.includes(
        type.value as (typeof GREATER_TYPES_SSN_CONFIDENCE_LEVELS)[number]
      )
    ) {
      return COMMON_OPTIONS_GREATER_SSN_CONFIDENCE_LEVELS;
    } else {
      return ALL_OPTIONS_SSN_CONFIDENCE_LEVELS;
    }
  });

  return { sentilinkConfidenceLevels };
};

export const useTimeInBusinessOptions = () => {
  const { t } = useI18n();
  return {
    days: t("COMMON.DAYS"),
    months: t("COMMON.MONTHS"),
    years: t("COMMON.YEARS")
  };
};

export const useAttributesOptions = () => {
  const { getters } = useStore();

  const attributeOptions = computed<IAttribute[]>(() => [
    ...getters["orchestration/appDataAttributes"],
    ...getters["orchestration/customAttributesAttributes"],
    ...getters["orchestration/activeServiceAttributes"]
  ]);
  return {
    attributeOptions
  };
};

export const useAttributesOptionsMap = () => {
  const { getters } = useStore();

  const activeDataServices = computed(() => {
    const activeServices: IDataServices =
      getters["orchestration/activeServices"];
    return activeServices.all_data_services;
  });

  const activeOther = computed(() => {
    const activeServices: IDataServices | undefined =
      getters["orchestration/activeServices"];
    return activeServices?.other || [];
  });

  const dataAttributes = computed(() => {
    const result: Record<string, IServiceAttribute> = {};
    activeDataServices.value?.forEach((service: IDataService) => {
      Object.keys(service.data_service_details || []).forEach((serviceId) => {
        if (service.data_service_details) {
          const serviceName =
            service.services?.find(
              (service) => service.id.toString() === serviceId
            )?.name || "";

          Object.values(service.data_service_details[serviceId]).forEach(
            (attributes) =>
              attributes.forEach((attribute) => {
                result[attribute.id] = getServiceAttribute(
                  serviceName,
                  attribute
                );
              })
          );
        }
      });
      (service.attributes || []).forEach((attribute) => {
        const serviceName = service.services?.[0]?.name || "";
        result[attribute.id] = getServiceAttribute(serviceName, attribute);
      });
    });
    return result;
  });

  const otherAttributes = computed(() => {
    const result: Record<string, IServiceAttribute> = {};
    activeOther.value?.forEach((other: IDataService) => {
      if (other.title === SERVICE_TRIGGER) {
        return;
      }
      other.attributes?.forEach((attribute) => {
        result[attribute.id] = getServiceAttribute(other.title, attribute);
      });
    });
    return result;
  });

  const allAttributes = computed(() => ({
    ...dataAttributes.value,
    ...otherAttributes.value
  }));

  const customAttributes = computed(() => {
    return activeOther.value.reduce<
      Array<IServiceAttribute & { custom_id: string }>
    >((result, { attributes, title }) => {
      if (!attributes || title !== SERVICE_CUSTOM_ATTRIBUTES) {
        return result;
      }
      result.push(
        ...attributes.map((attr) => ({
          ...getServiceAttribute(title, attr),
          custom_id: (attr.context?.value as { id: string }).id
        }))
      );
      return result;
    }, []);
  });

  const findCustomServiceAttribute = (attrId: string) => {
    return customAttributes.value.find(({ custom_id }) => custom_id === attrId);
  };

  /**
   * Returns a map object in a form of [AttributeID]: ServiceID
   * Example:
   *  {
   *    "dnb_pi_l3_unexpected_error": 7,
   *    "dnb_unexpected_error": 7,
   *    "ekata_account_opening": 1,
   *    "ekata_address_risk": 1,
   *  }
   */
  const attributeToServiceMap = computed(() => {
    const serviceToAttributesMap = Object.values(
      activeDataServices.value || []
    ).reduce<Record<string, string[]>>(
      (serviceAccumulator, currrentService) => {
        const serviceDetails = currrentService.data_service_details || {};
        const serviceToAttributes = Object.keys(serviceDetails).reduce<
          Record<string, string[]>
        >((acc, serviceIdentifier) => {
          const allServiceAttributes = Object.values(
            serviceDetails[serviceIdentifier]
          ).flatMap((detail) => detail.map(({ id }) => id));

          acc[serviceIdentifier] = allServiceAttributes;
          return acc;
        }, {});

        Object.keys(serviceToAttributes).forEach((serviceIdentifier) => {
          const idExists = !!serviceAccumulator[serviceIdentifier];
          serviceAccumulator[serviceIdentifier] = idExists
            ? uniq([
                ...serviceAccumulator[serviceIdentifier],
                ...serviceToAttributes[serviceIdentifier]
              ])
            : serviceToAttributes[serviceIdentifier];
        });

        return serviceAccumulator;
      },
      {}
    );

    return Object.entries(serviceToAttributesMap).reduce<
      Record<string, number>
    >((attributeToService, [serviceIdentifier, attributes]) => {
      attributes.forEach((attribute) => {
        attributeToService[attribute] = Number(serviceIdentifier);
      });
      return attributeToService;
    }, {});
  });

  return {
    allAttributes,
    dataAttributes,
    otherAttributes,
    activeDataServices,
    findCustomServiceAttribute,
    customAttributes,
    attributeToServiceMap
  };
};
