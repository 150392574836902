<template>
  <div
    class="inline-flex rounded-full bg-white shadow mx-2 items-center justify-center space-x-1"
    :data-cy="model.title"
    :class="{
      'cursor-pointer': isDraggable,
      'cursor-not-allowed': !isDraggable,
      'z-100': isDragging
    }"
    :draggable="isDraggable"
    @dragstart="handleDragStart"
    @dragend="handleDragEnd"
  >
    <div
      class="flex items-center justify-center rounded-l-full py-2 pl-2"
      :class="{ 'bg-blue-100': isDragging }"
    >
      <icon-base
        :icon="IconDragHandle"
        width="12"
        height="20"
        view-box="0 0 24 24"
        :class="isDragging ? 'text-primary' : 'text-gray-400'"
      />
    </div>
    <icon-base
      :icon="icon"
      class="scale-90"
      :class="{ 'text-warning': icon === 'warning' }"
      width="16"
      height="16"
    />
    <lf-heading4 class="whitespace-nowrap pr-2">
      {{ model.title }}
    </lf-heading4>
  </div>
</template>
<script setup lang="ts">
import type { PropType } from "vue";
import { computed, getCurrentInstance, ref } from "vue";
import { useStore } from "vuex";

import {
  DO_DRAGGABLE_DEFAULT,
  DO_TYPE_CONDITIONAL,
  ICON_FOR_CONDITION
} from "@/helpers/constants";
import type { IConditionalArgument, IStep } from "@/models/orchestration";
import { useDoActiveTemplateStatus } from "@/hooks/orchestration";
import { ORCHESTRATION_DRAG_VAL_CHANGED } from "@/helpers/constants/events";
import LfHeading4 from "@/components/ui/text/LfHeading4.vue";
import IconDragHandle from "@/components/icons/IconDragHandle.vue";
import IconBase from "@/components/ui/IconBase.vue";

const props = defineProps({
  model: {
    type: Object as PropType<IConditionalArgument>,
    default: null
  }
});

const emitter =
  getCurrentInstance()?.appContext.config.globalProperties.emitter;

const { getters } = useStore();
const { isActiveTemplatePublished } = useDoActiveTemplateStatus();

const isDragging = ref(false);

const icon = computed(() => ICON_FOR_CONDITION[props.model?.id]);

const isDraggable = computed(() => {
  if (isActiveTemplatePublished.value) {
    return false;
  }

  const steps = getters["orchestration/activeTemplateSteps"] as IStep[];
  return !!steps?.length;
});

const handleDragStart = (e: DragEvent) => {
  isDragging.value = true;
  e.dataTransfer?.setData("text", JSON.stringify(props.model));
  emitter.emit(ORCHESTRATION_DRAG_VAL_CHANGED, {
    isDragging: true,
    type: DO_TYPE_CONDITIONAL,
    id: props.model.id
  });
};

const handleDragEnd = () => {
  emitter.emit(ORCHESTRATION_DRAG_VAL_CHANGED, DO_DRAGGABLE_DEFAULT);
  isDragging.value = false;
};
</script>
